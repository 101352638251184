import React, { useState } from 'react';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { IChangePassword } from '../../../core/models';
import { PasswordUpdate } from '../../../../../apis/app.service';
import AppButton from '../../../../sharedcomponents/Buttons/AppButton/AppButton';
import { Spin } from 'antd';

const ChangePasswordSchema = Yup.object({
  oldPassword: Yup.string().required().label('Old password'),
  password: Yup.string().required().label('New password'),
});

let initialValues = {
  oldPassword: '',
  password: '',
};

export default function ChangePassword() {
  const [oldPasswordView, setOldPasswordView] = useState(false);
  const [passwordView, setpasswordView] = useState(false);
  const mutation = useMutation({
    mutationFn: PasswordUpdate,
    onSuccess: (res) => {
      const { message } = res;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const submitForm = async (values: IChangePassword, actions: FormikValues) => {
    mutation.mutate(values);
  };
  return (
    <div>
      <Formik
        validationSchema={ChangePasswordSchema}
        initialValues={initialValues}
        onSubmit={submitForm}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form className="form" noValidate>
            <div className="card mb-3">
              <div className="card-body">
                <h3 className="card-title">Change Password</h3>
                <div className="mb-4">
                  <label className="form-label">Old Password</label>
                  <div className="input-group">
                    <input
                      type={oldPasswordView ? 'text' : 'password'}
                      name="oldPassword"
                      className="form-control"
                      aria-describedby="password"
                      onChange={formik.handleChange}
                      value={formik.values.oldPassword}
                    />
                    <span
                      className="input-group-text"
                      onClick={() => setOldPasswordView(!oldPasswordView)}
                    >
                      <i className="fas fa-eye btn-icon-default"></i>
                    </span>
                  </div>

                  <div className="text-danger">
                    <ErrorMessage name="oldPassword" />
                  </div>
                </div>

                <div>
                  <label className="form-label">New Password</label>
                  <div className="input-group">
                    <input
                      type={passwordView ? 'text' : 'password'}
                      name="password"
                      className="form-control"
                      aria-describedby="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    <span
                      className="input-group-text"
                      onClick={() => setpasswordView(!passwordView)}
                    >
                      <i className="fas fa-eye btn-icon-default"></i>
                    </span>
                  </div>

                  <div className="text-danger">
                    <ErrorMessage name="password" />
                  </div>
                </div>
                <div className="card-footer text-md-end mt-4">
                  <AppButton
                    btnType="success"
                    type="submit"
                    disabled={mutation.isPending}
                  >
                    {mutation.isPending && <Spin />}
                    Save
                  </AppButton>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
