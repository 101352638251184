import React, { useState } from 'react';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { logoUpdate } from '../../../../../apis/app.service';
import AppButton from '../../../../sharedcomponents/Buttons/AppButton/AppButton';
import { Spin } from 'antd';
import { User } from '../../../../../store/core/models';
import { useAuth } from '../../../../../store/AuthProvider';

const logoSchema = Yup.object({
  logo: Yup.string().required().label('logo'),
});

let initialValues = {
  logo: '',
};

export default function CompanyLogo() {
  const { user, setUser } = useAuth();
  const mutation = useMutation({
    mutationFn: logoUpdate,
    onSuccess: (res) => {
      const { message, data } = res;
      if (user) {
        const update: User = {
          ...user,
          logo: data.logo,
        };
        setUser(update);
      }
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const submitForm = async (
    values: { logo: string },
    actions: FormikValues
  ) => {
    const formData = new FormData();

    formData.append('logo', values.logo);
    formData.append('test', 'value');
    mutation.mutate(formData);
  };
  return (
    <div>
      <Formik
        validationSchema={logoSchema}
        initialValues={initialValues}
        onSubmit={submitForm}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form className="form" noValidate>
            <div className="card mb-3">
              <div className="card-body">
                <h3 className="card-title">Institution logo</h3>
                <img
                  src={user?.logo}
                  alt="No logo"
                  className="mb-2"
                  style={{
                    width: '100%',
                    maxWidth: '80px',
                    height: '80px',
                    objectFit: 'contain',
                  }}
                />
                <div className="mb-4">
                  <div className="input-group">
                    <input
                      type="file"
                      name="logo"
                      className="form-control"
                      aria-describedby="logo"
                      accept="image/*,image/svg+xml"
                      onChange={(e: any) => {
                        const file: File = e.target.files[0] || '';
                        formik.setFieldValue('logo', file);
                      }}
                    />
                  </div>

                  <div className="text-danger">
                    <ErrorMessage name="logo" />
                  </div>
                </div>
                <div className="card-footer text-md-end mt-4">
                  <AppButton
                    btnType="success"
                    type="submit"
                    disabled={mutation.isPending}
                  >
                    {mutation.isPending && <Spin />}
                    Save
                  </AppButton>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
