import React, { useRef } from 'react';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Select } from 'antd';

import {
  IDepartmentHeadForm,
  IDepartmentHeadPayload,
} from '../../../core/models';
import {
  SCreateDepartmentHead,
  SUpdateDepartmentHead,
} from '../../../../../apis/app.service';

import CloseButton from '../../../../sharedcomponents/Buttons/IconButton/CloseButton';
import AppButton from '../../../../sharedcomponents/Buttons/AppButton/AppButton';
import { useSettingPage } from '../../../../../store/SettingsProvider';
import { useAuth } from '../../../../../store/AuthProvider';

const DepartmentHeadSchema = Yup.object({
  name: Yup.string().required().label('Name'),
  email: Yup.string().email().required().label('Email'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{9}$/, 'Phone number must be exactly 9 digits')
    .required()
    .label('Phone'),
  department: Yup.string().required().label('Department'),
});

export default function AddDepartmentHeadForm() {
  const queryClient = useQueryClient();
  const { departmentHead } = useSettingPage();
  const { user } = useAuth();
  const { department } = user!;
  const { action, data } = departmentHead;

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const MCreateDepartmentHead = useMutation({
    mutationFn: SCreateDepartmentHead,
    onSuccess: (res, variable) => {
      queryClient.invalidateQueries({ queryKey: ['DepartmentHeadList'] });
      const { message } = res;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });

      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const MUpdateDepartmentHead = useMutation({
    mutationFn: SUpdateDepartmentHead,
    onSuccess: (res, variable) => {
      queryClient.invalidateQueries({ queryKey: ['DepartmentHeadList'] });
      const { message } = res;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });

      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  //TODO: Add the admin account to allow easy management of users from admin dashboard.
  const submitDepartmentHeadForm = async (
    values: IDepartmentHeadForm,
    actions: FormikValues
  ) => {
    const payload: IDepartmentHeadPayload = {
      ...values,
      department: [values.department],
      phoneNumber: `+256${values.phoneNumber}`,
      type: 'external',
      role: 'department_head',
      parent: user!.id,
      admin_Account: user!.admin_Account,
    };

    if (action === 'create') {
      payload.created_By = '';
      MCreateDepartmentHead.mutate(payload);
    }

    if (action === 'edit') {
      if (data.id) {
        MUpdateDepartmentHead.mutate({ userId: data.id, body: payload });
      }
    }
  };

  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      id="addDepartmentHead"
      aria-labelledby="addDepartmentHead"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <Formik
            validationSchema={DepartmentHeadSchema}
            initialValues={data}
            onSubmit={submitDepartmentHeadForm}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form className="form" noValidate>
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="addDepartmentHead">
                    Add Department Head
                  </h1>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    ref={closeButtonRef}
                    className="d-none"
                    onClick={() => formik.resetForm()}
                  ></button>

                  <CloseButton
                    data-bs-dismiss="modal"
                    onClick={() => formik.resetForm()}
                  />
                </div>
                <div className="modal-body">
                  <div className="row mb-4">
                    <div className="col">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="E.g. Sam Odanga"
                        aria-label="Name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                      <div className="text-danger">
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col">
                      <label className="form-label">Email address</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="E.g name@example.com"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        disabled={action === 'edit'}
                      />
                      <div className="text-danger">
                        <ErrorMessage name="email" />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col">
                      <label className="form-label">Phone number</label>
                      <div className="input-group">
                        <span className="input-group-text">+256</span>

                        <input
                          type="text"
                          name="phoneNumber"
                          maxLength={9}
                          className="form-control"
                          placeholder="E.g 780000000"
                          aria-label="Phone"
                          onChange={formik.handleChange}
                          value={formik.values.phoneNumber}
                        />
                      </div>
                      <div className="text-danger">
                        <ErrorMessage name="phoneNumber" />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col">
                      <label className="form-label">Department</label>
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        size="large"
                        value={formik.values.department}
                        placeholder="Select department"
                        onChange={(value: string) => {
                          formik.setFieldValue('department', value);
                        }}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={department?.map((item) => {
                          return { value: item, label: item };
                        })}
                      />
                      <div className="text-danger">
                        <ErrorMessage name="department" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <AppButton
                    btnType="secondary"
                    data-bs-dismiss="modal"
                    onClick={() => formik.resetForm()}
                  >
                    cancel
                  </AppButton>
                  <AppButton btnType="success" type="submit">
                    {action}
                  </AppButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
