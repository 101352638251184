import { toAbsoluteUrl } from '../../../../helpers/AssetHelpers';
import KeyMetricsLoader from './components/Loader/KeyMetricsLoader';
import StatisticCard from './components/StatisticCard';
import { UseQueryResult } from '@tanstack/react-query';

interface CardStatistics {
  'Average-satisfaction'?: string;
  Responsiveness?: string;
  Count?: string;
  Compliment?: string;
  'Average-waiting-time'?: string;
}

interface Props {
  cardStatisticsQeury: UseQueryResult<{ data: CardStatistics }>;
}

function getAvgSatScoreColor(score: number) {
  if (score >= 0 && score < 20) return '#e52624';
  if (score >= 20 && score < 40) return '#f49729';
  if (score >= 40 && score < 60) return '#d7da00';
  if (score >= 60 && score < 80) return '#7fbb48';
  if (score >= 80 && score <= 100) return '#05893e';
  return '#000000'; // fallback color if out of range
}

export default function KeyMetrics({ cardStatisticsQeury }: Props) {
  const {
    data: response,
    isLoading,
    isFetching,
    isSuccess,
  } = cardStatisticsQeury;

  const cardStatistics = response?.data;
  const SatisfactionColor = getAvgSatScoreColor(
    parseInt(cardStatistics?.['Average-satisfaction'] || '0')
  );

  if (isLoading || isFetching) {
    return <KeyMetricsLoader />;
  }

  return (
    <div>
      {isSuccess ? (
        <div className="px-4 key-metrics">
          {cardStatistics?.['Average-satisfaction'] ? (
            <StatisticCard
              title="Average satisfaction rating"
              color="#000"
              style={{ background: SatisfactionColor }}
              stat={`${cardStatistics['Average-satisfaction']} %`}
              icon={
                <img
                  src={toAbsoluteUrl('media/icons/satisfaction.svg')}
                  alt="SEMA logo"
                  className="align-self-center mb-2"
                  height="40px"
                />
              }
            />
          ) : null}

          {cardStatistics?.['Responsiveness'] ? (
            <StatisticCard
              title="Responsiveness"
              color="#D7DA00"
              stat={`${cardStatistics['Responsiveness']} %`}
              icon={
                <img
                  src={toAbsoluteUrl('media/icons/responsiveness.svg')}
                  alt="SEMA logo"
                  className="align-self-center mb-2"
                  height="40px"
                />
              }
            />
          ) : null}

          {cardStatistics?.['Count'] ? (
            <StatisticCard
              title="Interviwed Citizens"
              color="#9FA3FF"
              stat={`${cardStatistics['Count']}`}
              icon={
                <img
                  src={toAbsoluteUrl('media/icons/interviewedCitizens.svg')}
                  alt="SEMA logo"
                  className="align-self-center mb-2"
                  height="40px"
                />
              }
            />
          ) : null}

          {cardStatistics?.['Compliment'] ? (
            <StatisticCard
              title="Compliment"
              color="#05893E"
              stat={`${cardStatistics['Compliment']} %`}
              icon={
                <img
                  src={toAbsoluteUrl('media/icons/compliment.svg')}
                  alt="SEMA logo"
                  className="align-self-center mb-2"
                  height="40px"
                />
              }
            />
          ) : null}
          {cardStatistics?.['Average-waiting-time'] ? (
            <StatisticCard
              title="Average waiting time"
              color="#59676A"
              stat={`${cardStatistics['Average-waiting-time']} mins`}
              icon={
                <img
                  src={toAbsoluteUrl('media/icons/waitingTime.svg')}
                  alt="SEMA logo"
                  className="align-self-center mb-2"
                  height="40px"
                />
              }
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
