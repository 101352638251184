import React from 'react';
import { useAuth } from '../../../../store/AuthProvider';
interface HeaderProps {
  month: string;
  year: string;
}
export default function Header({ month, year }: HeaderProps) {
  const { user } = useAuth();
  return (
    <div className="px-4">
      <div className="text-center">
        <h5
          className="text-uppercase py-2"
          style={{ background: '#000', color: '#fff' }}
        >
          <strong>
            {month} {year} - CITIZEN FEEDBACK REPORT
          </strong>
        </h5>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        {user?.logo && (
          <img
            src={user.logo}
            alt=""
            className=""
            style={{
              width: '100%',
              maxWidth: '80px',
              height: '80px',
              objectFit: 'contain',
            }}
          />
        )}

        <h1 className="text-uppercase">
          <strong>
            {user?.institution?.name || `${user?.firstName} ${user?.lastName}`}
          </strong>
        </h1>
        <img
          src="https://profile-photo-prod-sema.s3.amazonaws.com/SEMA%20LOGO%20PRIMARY.png"
          alt=""
          className=""
          style={{
            width: '100%',
            maxWidth: '80px',
            height: '80px',
            objectFit: 'contain',
          }}
        />
      </div>
    </div>
  );
}
