import React from 'react';
import ContactInformation from './components/ContactInformation';
import ChangePassword from './components/ChangePassword';
import DeleteAccount from './components/DeleteAccount';
import CompanyLogo from './components/CompanyLogo';

export default function Profile() {
  return (
    <div className="py-4">
      <ContactInformation />
      <CompanyLogo />
      <ChangePassword />
      <DeleteAccount />
    </div>
  );
}
