import React, { ReactNode } from 'react';
interface Props {
  stat: string;
  title: string;
  color: string;
  icon: ReactNode;
  style?: any;
}
export default function StatisticCard({
  stat,
  title,
  color,
  icon,
  style,
}: Props) {
  return (
    <div className="card" style={style}>
      <div className="card-body d-flex justify-content-between align-items-center">
        <div>
          <h2 style={{ color }}>
            <b>{stat}</b>
          </h2>
          <h6>{title}</h6>
        </div>
        <div>{icon}</div>
      </div>
    </div>
  );
}
